import './ficha_atraccion.scss';

import { graphql } from 'gatsby';

import React from 'react';
import { Freya } from '../src/components/Freya/Freya';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import Layout from '../src/components/Layout/Layout';
import Loki from '../src/components/Loki/lokipane';
import SEO from '../src/helpers/seo';
import { Saga } from '../src/components/Saga/Saga';
import tt from '../src/helpers/translation';

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query entornoDetailsQuery($population_name: String!, $locale: String!) {
    allEntornoDetailsSeoData(filter: { tag: { eq: $population_name }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
          tag
        }
      }
    }
    allEntornoDetailsHeaderBlock(filter: { tag: { eq: $population_name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allEntornoDetailsImageHeaderBlock(
      filter: { tag: { eq: $population_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allMultiColumnentornoDetails(filter: { tag: { eq: $population_name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          body_left
        }
      }
    }
    allEntornoDetailsPromotionalTextBlock(
      filter: { tag: { eq: $population_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          cardTitle
        }
      }
    }
    allEntornoDetailsGallery(filter: { tag: { eq: $population_name }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }

    firstImage: allEntornoDetailsGallery(filter: { tag: { eq: $population_name } }, limit: 1) {
      edges {
        node {
          image
          image_alt
        }
      }
    }

    bigLokiPics: allEntornoDetailsGallery(filter: { tag: { eq: $population_name } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allEntornoDetailsFreya(filter: { lang: { eq: $locale }, tag: { eq: $population_name } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          title
          description
          isSlide
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allEntornoDetailsRichData(filter: { lang: { eq: $locale }, tag: { eq: $population_name } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;

class entornoDetails extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allEntornoDetailsHeaderBlock.edges[0].node.title,
      subtitle: this.props.data.allEntornoDetailsHeaderBlock.edges[0].node.description,
      image: {
        url: this.props.data.allEntornoDetailsImageHeaderBlock.edges[0].node.localImage
          .childImageSharp.fluid,
      },
      imageMobile: {
        url: this.props.data.allEntornoDetailsImageHeaderBlock.edges[0].node.localImage
          .childImageSharp.fluid,
      },
    };

    const sagaData = {
      image: this.props.data.firstImage.edges[0].node.image,
      altImage: this.props.data.firstImage.edges[0].node.image_alt,
      title: this.props.data.allMultiColumnentornoDetails.edges[0].node.title,
      summary: this.props.data.allMultiColumnentornoDetails.edges[0].node.body_left,
      textBox: this.props.data.allEntornoDetailsPromotionalTextBlock.edges[0].node.cardTitle,
      social: false,
    };

    const PrepareforFreya = (src) => ({
      isSlider: src.edges[0].node.isSlide,
      cards: src.edges
        .map((e) => ({
          image: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
          altImage: e.node.image_alt,
          imgsize: e.node.imgsize,
          title: e.node.title,
          buttons: e.node.buttons,
        }))
        .filter((e) => e.image !== undefined),
    });

    const PrepareforLoki = () => ({
      arrayImg: this.props.data.allEntornoDetailsGallery.edges
        .map((e) => ({
          img: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
          altImage: e.node.image_alt,
        }))
        .filter((e) => e.img !== undefined),
      bigArrayImg: this.props.data.bigLokiPics.edges
        .map((e) => ({
          img: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
          altImage: e.node.image_alt,
        }))
        .filter((e) => e.img !== undefined),
    });

    let zone;

    if (this.props.pageContext.zone_name === 'cataluna') {
      zone = tt('EXPLORA CATALUÑA', this.props.pageContext.locale);
    } else if (this.props.pageContext.zone_name === 'costa-dorada') {
      zone = tt('EXPLORA LA COSTA DORADA', this.props.pageContext.locale);
    } else if (this.props.pageContext.zone_name === 'barcelona') {
      zone = tt('EXPLORA BARCELONA', this.props.pageContext.locale);
    }

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allEntornoDetailsSeoData.edges[0].node._0.title}
          description={this.props.data.allEntornoDetailsSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allEntornoDetailsRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={
            this.props.data.allEntornoDetailsImageHeaderBlock.edges[0].node.localImage
              .childImageSharp.fluid
          }
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="rides-details-content">
              <div className="top-shop-details">
                {/* <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={heimdallData.name}
                options={this.props.pageContext.options}
              /> */}
              </div>
              <Saga data={sagaData} />
              <div className="loki-content">
                {!this.props.data.allEntornoDetailsGallery.length ? (
                  <H4 title={tt('GALERIA DE FOTOS', this.props.pageContext.locale)} />
                ) : null}
                {this.props.data.allEntornoDetailsGallery && <Loki data={PrepareforLoki()} />}
              </div>
              {this.props.pageContext.options[0] !== 'cataluna' ? (
                <div className="promotion-content">
                  <H4 title={tt(zone, this.props.pageContext.locale)} />
                  <Freya data={PrepareforFreya(this.props.data.allEntornoDetailsFreya)} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default entornoDetails;
